<template>
  <div class="content">
    <h1 class="title title--big title--color title--indent">Проверка карты МКП</h1>
    <form class="form" @submit.prevent="sendForm">
      <div class="form__content">
        <div class="form__part">
          <div class="content__party content__party--fullstart">
            <div class="form-group form-group--stretch">
              <div class="form-control">
                <masked-input
                  v-model="search"
                  mask="A111-A111-A111-A111"
                  class="form-control__input"
                  placeholder="Введите номер карты для проверки"
                />
              </div>
              <div class="form-group__error" v-if="$v.clearSearch.$dirty && !$v.clearSearch.passwordAlpha">
                Принимаются только английские буквы алфавита и цифры
              </div>
              <div class="form-group__error" v-if="$v.clearSearch.$dirty && !$v.clearSearch.minLength">
                Номер карты должен быть равен 16 символам
              </div>
            </div>
            <button class="button button--mini" type="submit" ref="submit" :disabled="search && disabledSubmit">
              <span>Найти карту</span>
              <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
            </button>
          </div>
          <div v-if="card.card_number" class="card">
            <div class="card__top">
              <div class="card__part">
                <div class="card__desc">Номер карты:</div>
                <div class="card__number">{{ card.card_number }}</div>
              </div>
              <div class="card__img">
                <img src="@/assets/img/verify-icon.svg" alt="">
              </div>
            </div>
            <div class="card__info">
              <div v-if="card.full_name || card.full_name_en" class="card__title">Владелец карты:</div>
              <div class="card__wrapper">
                <div class="card__block">
                  <div v-if="card.full_name" class="card__text">
                    <img src="@/assets/img/russia-flag.svg" alt="">
                    {{ card.full_name }}
                  </div>
                  <div v-if="card.full_name_en" class="card__text">
                    <img src="@/assets/img/english-flag.svg" alt="">
                    {{ card.full_name_en }}
                  </div>
                  <div v-if="card.expiration_date" class="card__title">Действует до:</div>
                  <div v-if="card.expiration_date" class="card__text">{{ card.expiration_date }}</div>
                </div>
              </div>
              <div class="card__message">{{ card.message }}</div>
            </div>
          </div>
        </div>
        <div v-if="card.is_valid" class="form__part">
          <div class="form-group">
            <label for="comment">Комментарий</label>
            <div class="form-control">
            <textarea
              id="comment"
              name="comment"
              class="form-control__textarea"
              v-model.trim="comment"
              placeholder="Введите комментарий"
            >
            </textarea>
            </div>
          </div>
          <div class="holder holder--end">
            <button @click="onSendComment" class="button" type="button" ref="submitComment">
              <span>Оформить скидку</span>
              <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input';

import {validationMixin} from 'vuelidate';
import {minLength, helpers} from 'vuelidate/lib/validators';
const passwordAlpha = helpers.regex('enAlpha', /[a-z A-Z0-9]/i);

export default {
  mixins: [validationMixin],
  components: {MaskedInput},
  validations: {
    clearSearch: {
      minLength: minLength(19),
      passwordAlpha
    }
  },
  data() {
    return {
      search: '',
      card: {},
      comment: '',
    };
  },
  computed: {
    clearSearch() {
      return this.search.replaceAll('_', '');
    },
    disabledSubmit() {
      return this.search.includes('_');
    },
  },
  methods: {
    sendForm() {
      this.$v.clearSearch.$touch();
      if (this.$v.clearSearch.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.$refs.submit.classList.add('preload');
        this.$store.dispatch('partner/GET_VERIFY', this.clearSearch)
          .then(response => {
            this.$refs.submit.classList.remove('preload');
            this.card = response.data;
          })
          .catch(error => {
            this.$refs.submit.classList.remove('preload');
            if (error.request.status === 404)  this.card = JSON.parse(error.request.response);
            else if (error.request.status === 423) {
              this.card = JSON.parse(error.request.response);
              this.card.message = 'Карта невалидна';
            } else {
              this.$notify({
                type: 'error',
                title: 'Ошибка',
                text: error
              });
            }
          });
      }
    },
    onSendComment() {
      const data = {
        number: this.card.card_number,
        comment: this.comment,
      };
      this.$refs.submitComment.classList.add('preload');
      this.$store.dispatch('partner/POST_DISCOUNT', data)
        .then(response => {
          this.$refs.submitComment.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Скидка успешно оформлена'
          });
          this.card = {};
          this.comment = '';
          this.search = '';
        })
        .catch(response => {
          this.$refs.submitComment.classList.remove('preload');
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
  },
};
</script>
